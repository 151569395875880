import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const closeDropdown = () => setDropdownOpen(false);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="menu-icon" onClick={toggleDropdown}>
          <i className="fas fa-bars"></i> {/* Waffle icon */}
        </div>
        {dropdownOpen && (
          <div className="dropdown-menu">
            <ul className="dropdown-list">
              <li><RouterLink to="/cleansers" onClick={closeDropdown}>Cleansers</RouterLink></li>
              <li><RouterLink to="/moisturizers" onClick={closeDropdown}>Moisturizers</RouterLink></li>
              <li><RouterLink to="/toners" onClick={closeDropdown}>Toners</RouterLink></li>
              <li><RouterLink to="/sunscreen" onClick={closeDropdown}>Sunscreen</RouterLink></li>
              <li><RouterLink to="/exfoliants-serums" onClick={closeDropdown}>Exfoliants & Serums</RouterLink></li>
              <li><RouterLink to="/most-popular" onClick={closeDropdown}>Most Popular</RouterLink></li>
              <li><RouterLink to="/korean-products" onClick={closeDropdown}>Korean Products</RouterLink></li>
            </ul>
          </div>
        )}
        <RouterLink to="/" className="navbar-logo">
          SEI <i className="fa-solid fa-fire-flame-simple"></i>
        </RouterLink>
        <div className="nav-links-container">
          <RouterLink to="/" className="nav-links">
            HOME
          </RouterLink>
          <RouterLink to="/most-popular" className="shop-button">
            SHOP
          </RouterLink>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
