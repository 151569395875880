import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  // Function to scroll to the top
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='footer-container'>
      <section className="footer-subscription"></section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            {/* Attach the handleScrollToTop function to the onClick event */}
            <Link to='/' onClick={handleScrollToTop}>How It Works</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/sei.skincare?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              Contact
            </a>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/sei.skincare?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              Submit Video
            </a>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a
              className="social-icon-link instagram"
              href="https://www.instagram.com/sei.skincare?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <a
              className="social-icon-link tiktok"
              href="https://www.tiktok.com/@seiskincare?_t=8pgCRvwjrd0&_r=1"
              target="_blank"
              aria-label="TikTok"
              rel="noopener noreferrer"
            >
              TikTok
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link className="social-logo">
              Sei Skincare <i className='fa-solid fa-fire-flame-simple' />
            </Link>
          </div>
          <small className="website-rights">Oliver Herman Essentials © 2024</small>
          <div className="social-icons">
            <a 
              className="social-icon-link instagram"
              href="https://www.instagram.com/sei.skincare?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              aria-label='Instagram'
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a 
              className="social-icon-link tiktok"
              href="https://www.tiktok.com/@seiskincare?_t=8pgCRvwjrd0&_r=1"
              target="_blank"
              aria-label='TikTok'
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
