import React from 'react';
import './Moisturizers.css';

function Moisturizers() {
  const moisturizerData = {
    "All-Around/Combo Moisturizers": [
        {
          id: 1,
          image: require('../../images/isntree-gel-cream.jpg'),
          title: 'IsNtree Hyaluronic Acid Aqua Gel Cream',
          rating: 4.6,
          description: "Hyaluronic acid (Not pH adjusting, despite the name!) based cream that acts as a natural moisturizing factor. Contains plant-based extracts used as anti-inflammatory agents. Alcohol and fragrance free!",
          link: 'https://www.amazon.com/ISNTREE-Hyaluronic-Moisturizing-Protection-Smoothing/dp/B073JBWN86'
        },
        {
        id: 2,
        image: require('../../images/cerave-moisturizer.jpg'),
        title: 'Cerave Moisturizing Cream',
        rating: 4.7,
        description: "Contains hyaluronic acid and ceramides to replenish the skin barrier. Leaves the skin feeling smooth and moisturized.",
        link: 'https://www.amazon.com/CeraVe-Moisturizing-Cream-Daily-Moisturizer/dp/B00TTD9BRC'
        },
        {
        id: 3,
        image: require('../../images/cosrx-snail-mucin.jpg'),
        title: 'COSRX Snail Mucin Moisturizer',
        rating: 4.5,
        description: "Gel-type cream with snail mucin (popular in Korean Beauty) that soothes irritated and dehydrated skin.",
        link: 'https://www.amazon.com/COSRX-Moisturizer-Acne-prone-Sensitive-Parabens/dp/B01LEJ5MSK'
        },        
      ],
      "Dry/Normal Skin Moisturizers": [
        {
            id: 1,
            image: require('../../images/soon-jung-cream.jpg'),
            title: 'Soon Jung 2x Barrier Intesive Cream',
            rating: 4.6,
            description: "Low pH cream with madacassoside and panthensoside (skin soothers) that quickly hydrates the skin!",
            link: 'https://www.amazon.com/Intensive-Hypoallergenic-Hydrating-Sensitive-Water-oil/dp/B091PN6NPT',
        },
        {
            id: 2,
            image: require('../../images/joseon-dynasty-cream.jpg'),
            title: 'Beauty of Joseon Dynasty Cream',
            rating: 4.4,
            description: "Unique cream containing a blend of 2% niacinamide and 2% squalane to maintain skin oil-moisture balance. Lightweight moisturizer that replenishes the skin.",
            link: 'https://www.amazon.com/Beauty-Joseon-Dynasty-renewed-version/dp/B08WJQ3XJD',
        },
      ],
      "Oily/Acne-Prone Moisturizers": [
        {
            id: 1,
            image: require('../../images/anua-moisturizer.jpg'),
            title: 'ANUA Heartleaf 70 Cream',
            rating: 4.5,
            description: "Lightweight gel-like cream that is quickly absorbed by the skin. Contains ceramides to keep the skin hydrated and many skin-soothing ingredients such as cordata extract and pathenol.",
            link: 'https://www.amazon.com/Anua-Heartleaf-Intense-Calming-Cream/dp/B08T1FB8F2#customerReviews',
        },
        {
            id: 2,
            image: require('../../images/some-by-mi-miracle-cream.jpg'),
            title: 'SOME BY MI Miracle Cream',
            rating: 4.5,
            description: "Concentrated with tea tree leaf water and 4-Cica helping to soothe sensitive skin quickly and strengthen the skin's barrier. High amounts of tea tree leaf extract and truecica makes this cream suitable for sensitive skin!",
            link: 'https://www.amazon.com/SOME-MI-Moisturizing-Acne-Fighting-Anti-wrinkle/dp/B07KNP5CG3',
        },
      ]
  };

  return (
    <div className='moisturizer-container'>
      {Object.keys(moisturizerData).map(category => (
        <div key={category} className='category-section'>
          <h1 style={{ color: '#000000' }}>{category}</h1>
          <div className='products'>
            {moisturizerData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img src={product.image} alt={product.title} />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Moisturizers;
