import React from 'react';
import './Cleansers.css';

function Cleansers() {
  const cleansersData = {
    "All-Around Cleansers": [
      {
        id: 1,
        image: require('../../images/anua-oil-cleanser.jpg'),
        title: 'Anua Oil Cleanser',
        rating: 4.7,
        description: "Popular oil cleanser for double cleansing. Removes makeup effectively while purifying the skin.",
        link: 'https://www.amazon.com/ANUA-Heartleaf-Cleansing-Cleanser-Blackheads/dp/B0BN2PX8V3'
      },
      {
        id: 2,
        image: require('../../images/senka-cleanser.jpg'),
        title: 'Senka Cleanser',
        rating: 4.6,
        description: "Foaming cleanser suitable for most skin types, except dry skin.",
        link: 'https://www.amazon.com/Japan-Health-Personal-Senka-Perfect/dp/B01BTN5XEO'
      },
      {
        id: 3,
        image: require('../../images/anua_cleanser.jpg'),
        title: 'Anua Cleanser',
        rating: 4.5,
        description: "Effective foaming cleanser for double cleansing.",
        link: 'https://www.amazon.com/ANUA-Heartleaf-Quercetinol-Pore-Cleansing/dp/B0BVV8BNYJ'
      }
    ],
    "Dry/Normal Skin Cleansers": [
      {
        id: 1,
        image: require('../../images/cerave-dry.jpg'),
        title: 'Cerave Hydrating Cleanser',
        rating: 4.7,
        description: "Hydrates skin with ceramides and glycerin without stripping moisture.",
        link: 'https://www.amazon.com/CeraVe-Hydrating-Facial-Cleanser-Fragrance/dp/B01MSSDEPK'
      },
      {
        id: 2,
        image: require('../../images/roche-dry.jpg'),
        title: 'La Roche-Posay Hydrating Cleanser',
        rating: 4.6,
        description: "Hydrates skin with Prebiotic Thermal Water, Ceramide-3, Niacinamide, and Glycerin.",
        link: 'https://www.amazon.com/Roche-Posay-Toleriane-Hydrating-Gentle-Cleanser/dp/B01N7T7JKJ'
      },
      {
        id: 3,
        image: require('../../images/joseon-cleanser.jpg'),
        title: 'Beauty of Joseon Cleanser',
        rating: 4.6,
        description: "Contains plum water for moisture and citric acid for gentle exfoliation.",
        link: 'https://www.amazon.com/Beauty-Joseon-Refreshing-Cleanser-fl-oz/dp/B09N919SS5'
      },
    ],
    "Oily/Combo Skin Cleansers": [
      {
        id: 1,
        image: require('../../images/cerave-oily.jpg'),
        title: 'Cerave Oily Skin Cleanser',
        rating: 4.7,
        description: "Removes oil without disrupting the skin barrier.",
        link: 'https://www.amazon.com/CeraVe-Foaming-Facial-Cleanser-Washing/dp/B01N1LL62W'
      },
      {
        id: 2,
        image: require('../../images/cosrx-oily.jpg'),
        title: 'COSRX Low pH Cleanser',
        rating: 4.7,
        description: "Low pH cleanser that removes impurities while preserving the skin barrier.",
        link: 'https://www.amazon.com/COSRX-Good-Morning-Cleanser-150ml/dp/B016NRXO06'
      },
    ]
  };

  return (
    <div className='cleansers-container'>
      {Object.keys(cleansersData).map(category => (
        <div key={category} className='category-section'>
          <h1>{category}</h1>
          <div className='products'>
            {cleansersData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={product.image} 
                    alt={product.title} 
                  />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Cleansers;
