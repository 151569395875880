import React from 'react';
import './KoreanProducts.css';

function KoreanProducts() {
  const ksData = {
    "Cleansers": [
        {
          id: 1,
          image: require('../../images/cosrx-oily.jpg'),
          title: 'COSRX Low pH Cleanser',
          rating: 4.7,
          description: "Low pH cleanser that removes impurities and doesn't strip away the skin barrier.",
          link: 'https://www.amazon.com/COSRX-Good-Morning-Cleanser-150ml/dp/B016NRXO06/ref=sr_1_5?crid=1CGKVM6GTKOQA&dib=eyJ2IjoiMSJ9.M7wB8BjtDz8D6YnGDJAISbi_c1VNypYdYGcZsE1QLkNDYkipNvaGyEiHEuSCoaVbxceqaJPOEbJvWSTT5xzaXOFOC25D7vkl1UQrRyiyF4GuvY-kYBRoFAmHqh5oHNfCBU37XyUGlpN6KXHgoNpKRbfhyN9VwS4NmJcg18pIzUEBe427jJpkL-Rdw_Stt-O5Qb2tSv8mJjy3qtNvJPzK13YovaQgJEjgM7dHs8IHby79mjeL4PlWjeAN31HyYblcwwfI4Z9pYfvFcNESBY6t-Tmbt7bjj-1hi2-p3y9-w-U.O4mPmhpM80yvK4WdGJKeu8AHpLnZf4I7f6POIfeA1Oc&dib_tag=se&keywords=COSRX+Low+pH+Good+Morning+Gel+Cleanser&qid=1723410707&s=beauty&sprefix=cosrx+low+ph+good+morning+gel+cleanser%2Cbeauty%2C138&sr=1-5',
        },
        {
          id: 2,
          image: require('../../images/anua-oil-cleanser.jpg'),
          title: 'Anua Oil Cleanser',
          rating: 4.7,
          description: "Most popular pick for an oil-based double cleanse! Pairs well with the foaming Anua Cleanser.",
          link: 'https://www.amazon.com/ANUA-Heartleaf-Cleansing-Cleanser-Blackheads/dp/B0BN2PX8V3'
        },
        {
          id: 3,
          image: require('../../images/anua_cleanser.jpg'),
          title: 'Anua Cleanser',
          rating: 4.5,
          description: "Great foaming cleanser that works for a double cleanse.",
          link: 'https://www.amazon.com/ANUA-Heartleaf-Quercetinol-Pore-Cleansing/dp/B0BVV8BNYJ/ref=sr_1_9?crid=13NWJ60PMP5BJ&dib=eyJ2IjoiMSJ9.qCcBDScx-tEi1e--J9aw0FFNKJWqQ6vhfO1MofSHpAIhnIiY9dZOQGJyo8TVHVFUhRez0MPqCaEdLF-A0v1PY8qmcd-rCPvNdIw8WJ1206HEFvLH5y7crDCpHcBMUzqyUke_nJAXYnve8iwGVN6eB8kND23lklVWNhDrEBztl5GFBNw2HLQV9Jiv9ujuIdzW9G7TEK80D7ozA70ingTK9UyRbDxy8FIn4Gm1S74ttXLhBtSAKeBd97V72x03nB5lRGn7FBPTSTtGzas4fdchtazwzTwlkFVwu4HFAdulOCk.xSxeuFsguS1KLlf3kq8D-WlS4xUnUZKxkQY9QKyjE1s&dib_tag=se&keywords=cleanser&qid=1723590775&sprefix=cleanse%2Caps%2C258&sr=8-9',
        },
        {
          id: 4,
          image: require('../../images/joseon-cleanser.jpg'),
          title: 'Beauty of Joseon Refreshing Cleanser',
          rating: 4.6,
          description: "Contains plum water to provide abundant moisture, as well as citric acid and natural aha ingredients to gently exfoliate dead skin cells.",
          link: 'https://www.amazon.com/Beauty-Joseon-Refreshing-Cleanser-fl-oz/dp/B09N919SS5/ref=sr_1_15?crid=13NWJ60PMP5BJ&dib=eyJ2IjoiMSJ9.qCcBDScx-tEi1e--J9aw0FFNKJWqQ6vhfO1MofSHpAIhnIiY9dZOQGJyo8TVHVFUhRez0MPqCaEdLF-A0v1PY8qmcd-rCPvNdIw8WJ1206HEFvLH5y7crDCpHcBMUzqyUke_nJAXYnve8iwGVN6eB8kND23lklVWNhDrEBztl5GFBNw2HLQV9Jiv9ujuIdzW9G7TEK80D7ozA70ingTK9UyRbDxy8FIn4Gm1S74ttXLhBtSAKeBd97V72x03nB5lRGn7FBPTSTtGzas4fdchtazwzTwlkFVwu4HFAdulOCk.xSxeuFsguS1KLlf3kq8D-WlS4xUnUZKxkQY9QKyjE1s&dib_tag=se&keywords=cleanser&qid=1723590775&sprefix=cleanse%2Caps%2C258&sr=8-15',
        },
      ],
      "Moisturizers": [
        {
            id: 1,
            image: require('../../images/soon-jung-cream.jpg'),
            title: 'Soon Jung 2x Barrier Intesive Cream',
            rating: 4.6,
            description: "Low pH cream with madacassoside and panthensoside (skin soothers) that quickly hydrates the skin!",
            link: 'https://www.amazon.com/Intensive-Hypoallergenic-Hydrating-Sensitive-Water-oil/dp/B091PN6NPT',
        },
        {
          id: 2,
          image: require('../../images/some-by-mi-moisturizer.jpg'),
          title: 'SOMEBYMI Miracle Cream Moisturizer',
          rating: 4.5,
          description: "Great foaming cleanser that works for a double cleanse.",
          link: 'https://www.amazon.com/ANUA-Heartleaf-Quercetinol-Pore-Cleansing/dp/B0BVV8BNYJ/ref=sr_1_9?crid=13NWJ60PMP5BJ&dib=eyJ2IjoiMSJ9.qCcBDScx-tEi1e--J9aw0FFNKJWqQ6vhfO1MofSHpAIhnIiY9dZOQGJyo8TVHVFUhRez0MPqCaEdLF-A0v1PY8qmcd-rCPvNdIw8WJ1206HEFvLH5y7crDCpHcBMUzqyUke_nJAXYnve8iwGVN6eB8kND23lklVWNhDrEBztl5GFBNw2HLQV9Jiv9ujuIdzW9G7TEK80D7ozA70ingTK9UyRbDxy8FIn4Gm1S74ttXLhBtSAKeBd97V72x03nB5lRGn7FBPTSTtGzas4fdchtazwzTwlkFVwu4HFAdulOCk.xSxeuFsguS1KLlf3kq8D-WlS4xUnUZKxkQY9QKyjE1s&dib_tag=se&keywords=cleanser&qid=1723590775&sprefix=cleanse%2Caps%2C258&sr=8-9',
        },
        {
          id: 3,
          image: require('../../images/cosrx-snail-mucin.jpg'),
          title: 'COSRX Snail Mucin Moisturizer',
          rating: 4.5,
          description: "Gel-type cream with snail mucin (popular in Korean Beauty) that soothes irritated and dehydrated skin.",
          link: 'https://www.amazon.com/COSRX-Moisturizer-Acne-prone-Sensitive-Parabens/dp/B01LEJ5MSK'
        },
        {
          id: 4,
          image: require('../../images/joseon-dynasty-cream.jpg'),
          title: 'Beauty of Joseon Dynasty Cream',
          rating: 4.4,
          description: "Unique cream containing a blend of 2% niacinamide and 2% squalane to maintain skin oil-moisture balance. Lightweight moisturizer that replenishes the skin.",
          link: 'https://www.amazon.com/Beauty-Joseon-Dynasty-renewed-version/dp/B08WJQ3XJD',
        },      
        {
          id: 5,
          image: require('../../images/anua-moisturizer.jpg'),
          title: 'ANUA Heartleaf 70 Cream',
          rating: 4.5,
          description: "Lightweight gel-like cream that is quickly absorbed by the skin. Contains ceramides to keep the skin hydrated and many skin-soothing ingredients such as cordata extract and pathenol.",
          link: 'https://www.amazon.com/Anua-Heartleaf-Intense-Calming-Cream/dp/B08T1FB8F2#customerReviews',
        },  
      ],
      "Toners": [
        {
          id: 1,
          image: require('../../images/some-by-mi-toner.jpg'),
          title: 'SOME BY MI Toner',
          rating: 4.5,
          description: "All around exfoliating toner! Includes AHA/BHA/PHA & Papaya extract to remove dead skin and body wastes.",
          link: 'https://www.amazon.com/SOME-MI-Sensitive-Exfoliating-Oiliness/dp/B07BYJF7L7'
        },
        {
          id: 2,
          image: require('../../images/anua-toner.jpg'),
          title: 'Anua 77% Soothing Toner',
          rating: 4.5,
          description: "Toner designed to soothe, tone, hydrate, and balance the skin's pH level.",
          link: 'https://www.amazon.com/Heartleaf-Soothing-Trouble-Refreshing-Purifying/dp/B08CMS8P67'
        },
        {
          id: 3,
          image: require('../../images/cosrx-propolis-toner.jpg'),
          title: 'COSRX Propolis Toner',
          rating: 4.6,
          description: "Honey and propolis (mixture from bees) based extract that's great for dry skin! Contains no pH adjusting ingredients.",
          link: 'https://www.amazon.com/COSRX-Propolis-Synergy-Korean-Paraben/dp/B089SCWPJR',
        },
        {
          id: 4,
          image: require('../../images/isntree-toner.jpg'),
          title: 'ISNTREE Green Tea Fresh Toner',
          rating: 4.6,
          description: "Made for oily and acne-prone skin, an incredibly refreshing and gentle toner made from green tea extract!",
          link: 'https://www.amazon.com/Balancing-Soothing-Hydrating-Sensitive-Combination/dp/B073PQPDP3',
        },
      ],
      "Exfoliants and Serums": [
        {
          id: 1,
          image: require('../../images/joseon-green-plum-exfoliater.jpg'),
          title: 'Beauty of Joseon Green Plum AHA BHA Exfoliating Toner',
          rating: 4.6,
          description: "The gentlest of the listed exfoliaters with a mixture of 2% AHA and 0.5% BHA. A great gentle and hydrating exfoliater!",
          link: 'https://www.amazon.com/Beauty-Joseon-Green-Refreshing-Toner/dp/B0BBR3CYB8'
        },
        {
          id: 2,
          image: require('../../images/somebymi-serum.jpg'),
          title: 'SOMEBYMI Retinol Intense Reactivating Serum',
          rating: 4.2,
          description: "Serum containing Retinol 0.1%, Retinal 1ppm, and Bakuchiol 5,000ppm to fight against post-acne marks and improve skin texture!",
          link: 'https://www.amazon.com/Goodal-Green-Tangerine-Vitamin-Serum/dp/B0BD4PPRP3'
        },
        {
          id: 2,
          image: require('../../images/goodal-vitC.jpg'),
          title: 'GOODAL Green Tangerine Vitamin C Serum',
          rating: 4.4,
          description: "Vitamin C Dark Spot Serum made with tangerine extract that targets dark spots and hyperpigmentation!",
          link: 'https://www.amazon.com/Goodal-Green-Tangerine-Vitamin-Serum/dp/B0BD4PPRP3'
        },
        {
          id: 3,
          image: require('../../images/isntree-chestnut-essence.jpg'),
          title: 'ISNTREE Chestnut AHA 8% Clear Essence',
          rating: 4.2,
          description: "A mild exfoliater with glycolic acid, the smallest AHA, which exfoliates the deepest to fight against hyperpigmentation! ",
          link: 'https://www.amazon.com/Chestnut-tightening-chemical-upcycling-ingredients/dp/B07VZY8KYB'
        },
      ],
      "Facial sunscreen": [
        {
          id: 2,
          image: require('../../images/joseon-sunscreen.jpg'),
          title: 'Beauty of Joseon Relief Sun',
          rating: 4.9,
          description: "All organic (mineral) sunscreen containing 5 UV filters all covering the UVB and UVA1/UVA2 spectrums. Doesn't feel greasy and rubs into the skin well without leaving a white cast.",
          link: 'https://beautyofjoseon.com/products/relief-sun-rice-probiotics?srsltid=AfmBOopXj5fbHnN4Nf2Csvv3qWbtWN4iejxxqh3l-WIXbnYrGtIf56dc'
        },
        {
          id: 3,
          image: require('../../images/roundlab-sunscreen.jpg'),
          title: "ROUND LAB Birch Juice Moisturizing Sunscreen",
          rating: 4.5,
          description: "Moisturizing sunscreen that contains birch tree sap, nourishing skin by decreasing inflammation while purifying with its extensive minerals, enzymes, proteins and antioxidants.",
          link: "https://www.amazon.com/ROUND-LAB-Moisturizing-Protection-Friendly-Reef/dp/B09DGPRM22"
        },
      ]
  };

  return (
    <div className='ks-container'>
      {Object.keys(ksData).map(category => (
        <div key={category} className='category-section'>
          <h1 style={{ color: '#000000' }}>{category}</h1>
          <div className='products'>
            {ksData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={product.image} 
                    alt={product.title} 
                    style={
                      product.title === 'Anua Cleanser' || product.title === 'COSRX Low pH Cleanser' 
                      ? { width: '400px', height: '300px' } 
                      : { width: '220px', height: '280px' } // Default size for other images
                    }
                  />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default KoreanProducts;
