import React from 'react';
import './Exfoliants.css';

function ExfoliantsAndSerums() {
  const easData = {
    "Serums and Exfoliaters": [
      {
        id: 1,
        image: require('../../images/ordinary-salicylic-acid.jpg'),
        title: 'The Ordinary Exfoliating Salicylic Acid 2% Solution',
        rating: 4.5,
        description: "Hydroxy acid that helps exfoliate wonderfully against acne!",
        link: 'https://www.amazon.com/NIVEA-Protect-Super-Water-Pump/dp/B08YPCN6RL'
      },
      {
        id: 2,
        image: require('../../images/goodal-vitC.jpg'),
        title: 'GOODAL Green Tangerine Vitamin C Serum',
        rating: 4.4,
        description: "Vitamin C Dark Spot Serum made with tangerine extract that targets dark spots and hyperpigmentation!",
        link: 'https://www.amazon.com/Goodal-Green-Tangerine-Vitamin-Serum/dp/B0BD4PPRP3'
      },
      {
        id: 3,
        image: require('../../images/isntree-chestnut-essence.jpg'),
        title: 'ISNTREE Chestnut AHA 8% Clear Essence',
        rating: 4.2,
        description: "A mild exfoliater with glycolic acid, the smallest AHA, which exfoliates the deepest to fight against hyperpigmentation!",
        link: 'https://www.amazon.com/Chestnut-tightening-chemical-upcycling-ingredients/dp/B07VZY8KYB'
      },
      {
        id: 4,
        image: require('../../images/joseon-green-plum-exfoliater.jpg'),
        title: 'Beauty of Joseon Green Plum AHA BHA Exfoliating Toner',
        rating: 4.6,
        description: "The gentlest of the listed exfoliaters with a mixture of 2% AHA and 0.5% BHA. A great gentle and hydrating exfoliater!",
        link: 'https://www.amazon.com/Beauty-Joseon-Green-Refreshing-Toner/dp/B0BBR3CYB8'
      },
    ],
    
  };

  return (
    <div className='exfoliants-serums-container'>
      {Object.keys(easData).map(category => (
        <div key={category} className='category-section'>
          <h1 style={{ color: '#000000' }}>{category}</h1>
          <div className='products'>
            {easData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img src={product.image} alt={product.title} />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ExfoliantsAndSerums;
