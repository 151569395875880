import React from 'react';
import './Sunscreen.css';

function Sunscreen() {
  const sunscreenData = {
    "Top Recommended SPF": [
      {
        id: 1,
        image: require('../../images/nivea-sunscreen.jpg'),
        title: 'NIVEA SPF50 Water Gel Sunscreen',
        rating: 4.7,
        description: "Scentless and lightweight water-based SPF that doesn't leave a white cast! Helps the skin glow.",
        link: 'https://www.amazon.com/NIVEA-Protect-Super-Water-Pump/dp/B08YPCN6RL'
      },
      {
        id: 2,
        image: require('../../images/joseon-sunscreen.jpg'),
        title: 'Beauty of Joseon Relief Sun',
        rating: 4.9,
        description: "All organic (mineral) sunscreen containing 5 UV filters all covering the UVB and UVA1/UVA2 spectrums. Doesn't feel greasy and rubs into the skin well without leaving a white cast.",
        link: 'https://beautyofjoseon.com/products/relief-sun-rice-probiotics?srsltid=AfmBOopXj5fbHnN4Nf2Csvv3qWbtWN4iejxxqh3l-WIXbnYrGtIf56dc'
      },
      {
        id: 3,
        image: require('../../images/roundlab-sunscreen.jpg'),
        title: "ROUND LAB Birch Juice Moisturizing Sunscreen",
        rating: 4.5,
        description: "Moisturizing sunscreen that contains birch tree sap, nourishing skin by decreasing inflammation while purifying with its extensive minerals, enzymes, proteins and antioxidants.",
        link: "https://www.amazon.com/ROUND-LAB-Moisturizing-Protection-Friendly-Reef/dp/B09DGPRM22"
      },
    ],
    
  };

  return (
    <div className='sunscreen-container'>
      {Object.keys(sunscreenData).map(category => (
        <div key={category} className='category-section'>
          <h1 style={{ color: '#000000' }}>{category}</h1>
          <div className='products'>
            {sunscreenData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img src={product.image} alt={product.title} />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Sunscreen;
