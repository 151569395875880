import React from 'react';
import './Toners.css';

function Toners() {
  const tonersData = {
    "All-Around Toners": [
      {
        id: 1,
        image: require('../../images/some-by-mi-toner.jpg'),
        title: 'SOME BY MI Toner',
        rating: 4.5,
        description: "All around exfoliating toner! Includes AHA/BHA/PHA & Papaya extract to remove dead skin and body wastes.",
        link: 'https://www.amazon.com/SOME-MI-Sensitive-Exfoliating-Oiliness/dp/B07BYJF7L7'
      },
      {
        id: 2,
        image: require('../../images/anua-toner.jpg'),
        title: 'Anua 77% Soothing Toner',
        rating: 4.5,
        description: "Toner designed to soothe, tone, hydrate, and balance the skin's pH level.",
        link: 'https://www.amazon.com/Heartleaf-Soothing-Trouble-Refreshing-Purifying/dp/B08CMS8P67'
      },
    ],
    "Dry/Normal Skin Toners": [
      {
        id: 1,
        image: require('../../images/cerave-toner.jpg'),
        title: 'Cerave Hydrating Toner',
        rating: 4.6,
        description: "Lightweight and pH balanced toner that gently removes impurities after cleansing. With ceramides, hyaluronic acid, and niacinamide.",
        link: 'https://www.amazon.com/CeraVe-Non-Alcoholic-Niacinamide-Fragrance-Free-Comedogenic/dp/B09LKXR62L',
      },
      {
        id: 2,
        image: require('../../images/cosrx-propolis-toner.jpg'),
        title: 'COSRX Propolis Toner',
        rating: 4.6,
        description: "Honey and propolis (mixture from bees) based extract that's great for dry skin! Contains no pH adjusting ingredients. Choose this over the Cerave toner if you have dry skin that is still prone to acne.",
        link: 'https://www.amazon.com/COSRX-Propolis-Synergy-Korean-Paraben/dp/B089SCWPJR',
      },
    ],
    "Oily/Acne-Prone Toners": [
      {
        id: 1,
        image: require('../../images/isntree-toner.jpg'),
        title: 'ISNTREE Green Tea Fresh Toner',
        rating: 4.6,
        description: "Incredibly refreshing and gentle toner made from green tea extract! Perfect for oily skin.",
        link: 'https://www.amazon.com/Balancing-Soothing-Hydrating-Sensitive-Combination/dp/B073PQPDP3',
      },
    ]
  };

  return (
    <div className='toners-container'>
      {Object.keys(tonersData).map(category => (
        <div key={category} className='category-section'>
          <h1 style={{ color: '#000000' }}>{category}</h1>
          <div className='products'>
            {tonersData[category].map(product => (
              <div key={product.id} className='product'>
                <a href={product.link} target="_blank" rel="noopener noreferrer">
                  <img src={product.image} alt={product.title} />
                </a>
                <h2>
                  <a href={product.link} target="_blank" rel="noopener noreferrer">{product.title}</a>
                </h2>
                <div className='rating'>
                  {'★'.repeat(Math.floor(product.rating)) + '☆'.repeat(5 - Math.floor(product.rating))}
                  <span>{product.rating}</span>
                </div>
                <p className='description'>{product.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Toners;
