import React from 'react';
import '../../App.css';
// import HeroSection from '../HeroSection';
import ScrollableSection from '../ScrollableSection';

function Home() {
  return (
    <>
      {/* <HeroSection /> */}
      <ScrollableSection />
    </>
  );
}

export default Home;
