import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Cleansers from './components/pages/Cleansers';
import ExfoliantsAndSerums from './components/pages/ExfoliantsAndSerums';
import Moisturizers from './components/pages/Moisturizers';
import Toners from './components/pages/Toners';
import MostPopular from './components/pages/MostPopular';
import Sunscreen from './components/pages/Sunscreen';
import KoreanProducts from './components/pages/KoreanProducts';

function App() {
  return (
    <Router>
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/most-popular" element={<MostPopular/>} /> 
          <Route path="/cleansers" element={<Cleansers />} />
          <Route path="/exfoliants-serums" element={<ExfoliantsAndSerums/>} />
          <Route path="moisturizers" element={<Moisturizers />} />
          <Route path="/sunscreen" element={<Sunscreen />} />
          <Route path="/toners" element={<Toners />} />
          <Route path="/korean-products" element={<KoreanProducts />} />
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
