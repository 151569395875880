import React from 'react';
import './ScrollableSection.css';
import { Link } from 'react-router-dom';

function ScrollableSection() {
  return (
    <div className="scrollable-section-container">
      {/* Hero Section */}
      <div className="hero-background">
        <h1>Sei Skincare</h1>
      </div>

      {/* Main Content */}
      <div className="scrollable-content">
        {/* Natural Clean Beauty Section */}
        <div className="rectangular-section">
          <div className="text-content">
            <h2>Natural Clean Beauty</h2>
            <p>
              At Sei, we believe in the power of natural ingredients. Our products are carefully curated to help you achieve a healthy, glowing complexion. Clean skincare is our priority, and our range is tailored to meet the needs of every skin type.
            </p>
            <p>Join us on a journey to healthier skin!</p>
          </div>
          <div className="image-content">
            <img src="/images/scrollable-rectangle-image.jpg" alt="Skincare Products" />
          </div>
        </div>

        {/* Korean Skincare Section */}
        <div className="korean-skincare-container">
          <h1 className="ks-text">Viral Korean Skincare Products</h1>
          <div className="ks-explore">
            <img src="/images/somebymi-toner.jpg" alt="Explore Skincare Products" />
            <div className="ks-explore-content">
              <p>Discover Korean skincare!</p>
              <button className="view-now-btn">
                <Link to="korean-products" className="view-now-link">VIEW</Link>
              </button>
            </div>
          </div>
        </div>

        {/* Most Popular Products Section */}
        <div className="most-popular-container">
          <Link to="most-popular" className="image-link">
            <div className="image-container">
              <span className="most-popular-text">Browse the most popular products!</span>
              <img src="/images/anua-set.jpg" alt="Most Popular Products" />
            </div>
          </Link>
        </div>

        {/* Product Grid Section */}
        <div className="grid-container">
          <div className="grid-item">
            <Link to="/cleansers" className="image-link">
              <div className="image-overlay">
                <img src="/images/scrollable-cleanser.jpeg" alt="Cleansers" />
                <span className="overlay-text">CLEANSERS</span>
              </div>
            </Link>
          </div>

          <div className="grid-item">
            <Link to="/moisturizers" className="image-link">
              <div className="image-overlay">
                <img src="/images/scrollable-moisturizer.jpg" alt="Moisturizers" />
                <span className="overlay-text">MOISTURIZERS</span>
              </div>
            </Link>
          </div>

          <div className="grid-item">
            <Link to="/toners" className="image-link">
              <div className="image-overlay">
                <img src="/images/scrollable-toner.jpg" alt="Toners" />
                <span className="overlay-text">TONERS</span>
              </div>
            </Link>
          </div>

          <div className="grid-item">
            <Link to="/sunscreen" className="image-link">
              <div className="image-overlay">
                <img src="/images/scrollable-sunscreen.jpg" alt="Sunscreen" />
                <span className="overlay-text">SUNSCREEN</span>
              </div>
            </Link>
          </div>
        </div>

        {/* Exfoliants Section */}
        <div className="exfoliants-container">
          <Link to="exfoliants-serums" className="image-link">
            <div className="exfoliants-image-container">
              <span className="exfoliants-text">EXFOLIANTS AND SERUMS</span>
              <img src="/images/exfoliants-img.jpg" alt="Exfoliants and Serums" />
            </div>
          </Link>
        </div>

        {/* Who Are We Section */}
        <div className="who-are-we-container">
          <div className="who-are-we-text">
            <h1>Who Are We?</h1>
            <p>
              At Sei, we are more than just a skincare brand – we are your partner in achieving healthy, radiant skin. Our mission is simple: to provide the most effective and popular skincare products, carefully selected based on expert reviews and proven ingredients. We believe that skincare should not only be clean but also give you the results you deserve.
            </p>
          </div>
          <div className="who-are-we-image">
            <img src="/images/who-are-we-img.jpg" alt="Who Are We" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScrollableSection;
